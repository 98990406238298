import React, { Component } from 'react';
import { getTabMenus } from 'lib/util';

import EmergencyProcessContainer from '../../../containers/Content/ManualProcess/Emergency/EmergencyProcessContainer';
import ManualProcessContainer from '../../../containers/Content/ManualProcess/Internal/ManualProcessContainer';
import ManualProcessVendorContainer from '../../../containers/Content/ManualProcess/Vendor/ManualProcessVendorContainer';
import { Tabs } from 'antd';
import UpdateAddressContainer from '../../../containers/Content/ManualProcess/UpdateAddress/UpdateAddressContainer';

const { TabPane } = Tabs;

interface IProps {
  handleChangeTabs: any;
  lastAccessFiture: any;
  sideMenus: any;
}

export default class IndexManualProcessComponent extends Component<IProps> {
  componentDidUpdate() {
    const handleChange = () => {
      if (this.props.lastAccessFiture === 'deliveryManual') {
        this.props.handleChangeTabs('1');
      } else if (this.props.lastAccessFiture === 'deliveryVendor') {
        this.props.handleChangeTabs('2');
      } else if (this.props.lastAccessFiture === 'deliveryEmergency') {
        this.props.handleChangeTabs('3');
      } else if (this.props.lastAccessFiture === 'deliveryUpdateAddress') {
        this.props.handleChangeTabs('4');
      }
    };
    if (this.props.lastAccessFiture !== null) {
      handleChange();
    }
  }
  render() {
    let a = '1';
    const tabMenus = getTabMenus(
      this.props.sideMenus,
      'Pengiriman',
      'Proses Manual'
    );

    if (this.props.lastAccessFiture === 'deliveryUpdateAddress') {
      a = '4';
    } else if (this.props.lastAccessFiture === 'deliveryEmergency') {
      a = '3';
    } else if (this.props.lastAccessFiture === 'deliveryVendor') {
      a = '2';
    } else {
      a = '1';
    }
    return (
      <div>
        <Tabs
          style={{
            paddingRight: '35px',
            paddingLeft: '35px',
            paddingTop: '35px',
          }}
          defaultActiveKey={a}
          activeKey={a}
          onChange={this.props.handleChangeTabs}
        >
          {tabMenus.map((item, index) => (
            <TabPane tab={<b>{item.label}</b>} key={index + 1}>
              {item.label === 'Scan Selesai' ? (
                <ManualProcessContainer />
              ) : item.label === 'Unggah Selesai' ? (
                <ManualProcessVendorContainer />
              ) : item.label === 'Emergency' ? (
                <EmergencyProcessContainer />
              ) : item.label === 'Perbaharui Alamat' ? (
                <UpdateAddressContainer />
              ) : null}
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}
