import { Col, Row, Tag } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { InputSelect, InputText } from '../../../apps/assets/components/CInput';

import CButton from '../../../apps/assets/components/CButton';
import CCard from '../../../apps/assets/components/CCard';
import CModal from '../../../apps/assets/components/CModal';
import CSpin from '../../../apps/assets/components/CSpin';
import React from 'react';
import validate from '../Validation/CreateComplaintFormValidation';

function CreateComplaintComponent(props) {
  const {
    createComplaintModalIsShow,
    handleCloseModalCreateComplaint,
    handleSearchWaybill,
    handleBackToInputInvestigate,
    complaintDetailDoc,
    branchList,
    courierInvestigationList,
    formValue,
    handleSubmit,
    handleClearCourier,
    isLoading,
    errorFormValue,
  } = props;
  const valueDetailDoc = complaintDetailDoc ? complaintDetailDoc : '';
  const validateInput = complaintDetailDoc ? false : true;
  const validateForm = valueDetailDoc.stats ? true : validateInput;
  const validateWaybillForm = valueDetailDoc.stats ? true : false;
  const formType = formValue ? formValue.formType : '';
  const courierValue = formValue ? formValue.courierInvestigate : '';
  // const branchInvestigateValue = formValue ? formValue.branchInvestigate : '';

  const valueValidateBtn = () => {
    const validateBtnCreate = !courierValue ? true : false;
    const validateBtnNext =
      !errorFormValue.complaintSubject &&
      !errorFormValue.phoneNumber &&
      !errorFormValue.detailComplaint
        ? false
        : true;

    if (formType === 'inputData') {
      return validateBtnNext;
    } else {
      return validateBtnCreate;
    }
  };
  const btnSearchCircle = [
    {
      type: 'primary',
      shape: 'circle',
      className: 'btnSearchWaybillComplaint',
      onClick: handleSearchWaybill,
      isLoading: isLoading,
      icon: 'search',
      id: 'btnSearchWaybill',
      disabled: validateWaybillForm,
    },
  ];
  const btnSubmitComplaint = [
    formType === 'assignCourier'
      ? {
          type: 'primary',
          className: 'btnResetDefault',
          onClick: handleBackToInputInvestigate,
          isLoading: isLoading,
          disabled: false,
          content: 'Kembali',
          id: 'btnBackComplaint',
        }
      : '',
    {
      type: 'primary',
      className: 'btnDefaultSubmitNextComplaint',
      onClick: handleSubmit,
      isLoading: isLoading,
      disabled: valueValidateBtn(),
      content: formType === 'inputData' ? 'Selanjutnya' : 'Kirim',
      id: 'btnSubmitComplaint',
    },
  ];
  const address =
    valueDetailDoc.shipToAddress === null ||
    valueDetailDoc.shipToAddress === undefined
      ? ''
      : `${Object.values(JSON.parse(valueDetailDoc.shipToAddress)).join(' ')}`;

  const renderData = (label: any, content: any) => {
    return (
      <>
        <Col span={6}>
          <div className="detailLabelDataComplaint">
            <span>{label}</span>
          </div>
        </Col>
        <Col span={18}>
          <div className="detailValueDataComplaint">
            <span>{`: ${content}`}</span>
          </div>
        </Col>
      </>
    );
  };

  const cardDetailDoc = () => {
    return (
      <CCard
        cardClassName={'cardDetailDocComplaint'}
        cardTitle={
          <span className="cardTitleDefaultWhite">Detail Waybill</span>
        }
        cardExtra={''}
        cardContent={
          <React.Fragment>
            <Row>
              {renderData('Waybill', valueDetailDoc.waybillCode)}
              {renderData('Tanggal Cycle', valueDetailDoc.cycle)}
              {renderData('Barcode', valueDetailDoc.barcode)}
              {renderData('Alamat', address)}
              {renderData('Nama Pemilik', valueDetailDoc.shipToName)}
              {renderData(
                'No Telp',
                valueDetailDoc.shipToPhoneAccount === null ||
                  valueDetailDoc.shipToPhoneAccount === ''
                  ? '-'
                  : valueDetailDoc.shipToPhoneAccount
              )}
              {formType === 'assignCourier'
                ? renderData('Status Kirim', 'isi status kirim')
                : ''}
              {formType === 'assignCourier'
                ? renderData('Subjek keluhan', formValue.complaintSubject)
                : ''}
              {formType === 'assignCourier'
                ? renderData('Penjelasan', formValue.detailComplaint)
                : ''}
            </Row>
          </React.Fragment>
        }
      />
    );
  };

  const modalContent = () => {
    const showInputFormInvestigate = () => {
      return (
        <>
          <Tag color={'green'}>
            <span>
              <b>Baru</b>
            </span>
          </Tag>
          <Row>
            <Col span={10} xs={8} md={22} xl={10}>
              <Field
                name="waybill"
                component={InputText}
                placeholder={'Masukan nomor waybill'}
                label={'Waybill'}
                maxlength={30}
                disabled={validateWaybillForm}
              />
            </Col>
            <Col span={2}>
              <CButton buttonData={btnSearchCircle} />
            </Col>
            <Col span={12}>
              <Field
                className="inputFormPhoneNumbComplaint"
                name="phoneNumber"
                component={InputText}
                addonBefore={'+62'}
                placeholder={'Masukan nomor telpon'}
                label={'No Telp Yang Bisa Dihubungi'}
                maxlength={15}
                disabled={validateForm}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Field
                name="complaintSubject"
                component={InputText}
                placeholder={'Masukan subjek keluhan'}
                label={'Subjek Keluhan'}
                maxlength={150}
                disabled={validateForm}
              />
              <Field
                name="detailComplaint"
                component={InputText}
                textArea={true}
                placeholder={'Masukan penjelasan'}
                label={'Penjelasan'}
                maxlength={400}
                disabled={validateForm}
              />
            </Col>
          </Row>
        </>
      );
    };
    const showAssignCourierForm = () => {
      return (
        <>
          <Field
            name="branchInvestigate"
            component={InputSelect}
            idComponent="inputBranchInvestigate"
            // dafaultItemName="Pilih Posko"
            additionalHandleChangeFunc={handleClearCourier}
            data={branchList}
            label={'Posko'}
          />
          <Field
            name="courierInvestigate"
            component={InputSelect}
            idComponent="inputComplaintStatus"
            customOption={'labelAndCode'}
            data={courierInvestigationList}
            label={'Kurir'}
            // dafaultItemName="Pilih Kurir"
          />
        </>
      );
    };
    return (
      <div className="defaultPaddingModal">
        {complaintDetailDoc ? cardDetailDoc() : ''}
        {formType === 'inputData'
          ? showInputFormInvestigate()
          : showAssignCourierForm()}
        <Row className="floatRight">
          <CButton buttonData={btnSubmitComplaint} />
        </Row>
      </div>
    );
  };
  return (
    <React.Fragment>
      <CSpin
        spinning={isLoading}
        size={'large'}
        content={
          <CModal
            className={'modalComplaintForm'}
            teksTitle={'TAMBAH TIKET KELUHAN BARU'}
            visible={createComplaintModalIsShow}
            onCancel={handleCloseModalCreateComplaint}
            content={modalContent()}
          />
        }
      />
    </React.Fragment>
  );
}
const ComplaintFormComponent = reduxForm({
  form: 'complaintForm',
  shouldError: () => {
    return true;
  },
  validate,
})(CreateComplaintComponent);
export default ComplaintFormComponent;
