import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Modal } from 'antd';

import {
  getAllCustomers,
  getProductByCustomers,
  getAllBranch,
  getCourierWithBranch,
} from 'services/GeneralService';
import {
  getDeliveryData,
  downloadDeliveryData,
  getDeliveryCountData,
} from 'services/DeliveryService';
import ModalStatus from 'materials/ModalStatus';
import { SelectOptionsInterface, DataTableResProps } from 'interface/general';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';

import FilterDataDeliveryComponent from './FilterDataDeliveryComponent';
import TableDataDeliveryComponent from './TableDataDeliveryComponent';
import { processProgressRequest, downloadBlob } from 'lib/util';
import moment from 'moment';

export type FilterDataDeliveryType = {
  typePeriode: string;
  periodeDelivery: any[];
  statusDelivery: any[];
  customer: number | null;
  customerProduct: number | null;
  branch: number | null;
  courier: number | null;
  isPhysicalDocument: boolean;
  search?: string | null;
  page?: number;
};

export type DataDeliveryCountType = {
  inProcess: number;
  success: number;
  return: number;
  total: number;
};

const defaultFilterDataDelivery = {
  typePeriode: 'Cycle',
  periodeDelivery: [null, null],
  statusDelivery: [102, 103, 104],
  customer: null,
  customerProduct: null,
  branch: null,
  courier: null,
  isPhysicalDocument: false,
  search: null,
  page: 10,
};

const defaultDeliveryCount = {
  inProcess: 0,
  success: 0,
  return: 0,
  total: 0,
};

function Index({ IdBranch }: { IdBranch?: number }) {
  const [showChangeFilter, setShowChangeFilter] = useState<boolean>(false);
  const [filterDataDelivery, setFilterDataDelivery] =
    useState<FilterDataDeliveryType>(defaultFilterDataDelivery);
  const [dataCustomers, setDataCustomers] = useState<SelectOptionsInterface[]>(
    []
  );
  const [dataProducts, setDataProducs] = useState<SelectOptionsInterface[]>([]);
  const [dataBranchs, setDataBranchs] = useState<any[]>([]);
  const [dataCourier, setDataCourier] = useState<any[]>([]);
  const [dataTableDelivery, setDataTableDelivery] =
    useState<DataTableResProps | null>(null);
  const [selectedDownloadtype, setSelectedDownloadType] = useState<
    string | null
  >(null);
  const [dataDeliveryCount, setDataDeliveryCount] =
    useState<DataDeliveryCountType>(defaultDeliveryCount);

  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);

  const { mutate: mutateDownloadSingle, isLoading: isLoadingDownload } =
    useMutation((data: any) => downloadDeliveryData(data, progressHandler), {
      onSuccess: res => {
        if (res.status === 200) {
          const customerName =
            dataCustomers.find(
              (value: any) => value.id === filterDataDelivery?.customer
            )?.name || '';
          const courierName =
            dataCourier.find(
              (value: any) => value.id === filterDataDelivery?.courier
            )?.firstName || '';
          const DateNow = moment().format('YYYY-MM-DD HH:mm:ss');

          downloadBlob(
            res.data,
            `${selectedDownloadtype} ${customerName} ${courierName} ${DateNow}`,
            'xls'
          );
          ModalStatus({
            status: 'success',
            title: 'Download Berhasil',
          });
        } else {
          setLoadingProgress(0);
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content:
              res.response?.data?.Message ||
              'Terjadi kesalahan pada server, mohon hubungi admin untuk tindakan lebih lanjut',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  const { isLoading: isLoadingCustomers } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProducts', { id: filterDataDelivery.customer }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataProducs(datas);
    },
    enabled: !!filterDataDelivery.customer,
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingBranch } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataListBranch'],
    queryFn: () => getAllBranch(),
    onSuccess(res) {
      if (res?.data?.length > 0) {
        setDataBranchs(res.data || []);
      } else {
        setDataBranchs([]);
      }
    },
  });

  const { isLoading: isLoadingCourier } = useQuery<AxiosResponse, Error>({
    queryKey: [
      'dataCourierByBranch',
      { branchItems: [filterDataDelivery.branch] },
    ],
    queryFn: ({ queryKey }) => getCourierWithBranch(queryKey),
    enabled: !!filterDataDelivery.branch,
    onSuccess(res) {
      if (res?.data.length > 0) {
        setDataCourier(res.data);
      } else {
        setDataCourier([]);
      }
    },
  });

  const { mutate: mutateDeliveryData, isLoading: isLoadingMutate } =
    useMutation(getDeliveryData, {
      onSuccess: res => {
        if (res.status === 200) {
          setDataTableDelivery(res.data);
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { mutate: mutateDeliveryCount, isLoading: isLoadingCount } =
    useMutation(getDeliveryCountData, {
      onSuccess: res => {
        if (res.status === 200) {
          setDataDeliveryCount(res.data);
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  useEffect(() => {
    if (IdBranch) {
      handleChangeFilter('branch', IdBranch);
    }
  }, [IdBranch]);

  const handleChangeFilter = (name: string, value: any) => {
    setFilterDataDelivery(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetFilter = () => {
    setFilterDataDelivery(defaultFilterDataDelivery);
    setDataTableDelivery(null);
  };

  const handleSubmitFilter = () => {
    const data = {
      TimeStart: filterDataDelivery.periodeDelivery[0].format('YYYYMMDD'),
      TimeEnd: filterDataDelivery.periodeDelivery[1].format('YYYYMMDD'),
      Customer: filterDataDelivery.customer,
      Product: filterDataDelivery.customerProduct,
      Branch: filterDataDelivery.branch,
      Courier: filterDataDelivery.courier,
      DeliveryTime: filterDataDelivery.typePeriode,
      PhysicalDocument: filterDataDelivery.isPhysicalDocument,
      StatusDelivery: filterDataDelivery.statusDelivery,
    };

    mutateDeliveryData(data);
    mutateDeliveryCount(data);
  };

  const handleSearchName = (value: string) => {
    handleChangeFilter('search', value);
    const data = {
      TimeStart: filterDataDelivery.periodeDelivery[0].format('YYYYMMDD'),
      TimeEnd: filterDataDelivery.periodeDelivery[1].format('YYYYMMDD'),
      Customer: filterDataDelivery.customer,
      Product: filterDataDelivery.customerProduct,
      Branch: filterDataDelivery.branch,
      Courier: filterDataDelivery.courier,
      DeliveryTime: filterDataDelivery.typePeriode,
      PhysicalDocument: filterDataDelivery.isPhysicalDocument,
      StatusDelivery: filterDataDelivery.statusDelivery,
      Search: value,
      page: 0,
    };

    mutateDeliveryData(data);
  };

  const handlePage = (value: number) => {
    const data = {
      TimeStart: filterDataDelivery.periodeDelivery[0].format('YYYYMMDD'),
      TimeEnd: filterDataDelivery.periodeDelivery[1].format('YYYYMMDD'),
      Customer: filterDataDelivery.customer,
      Product: filterDataDelivery.customerProduct,
      Branch: filterDataDelivery.branch,
      Courier: filterDataDelivery.courier,
      DeliveryTime: filterDataDelivery.typePeriode,
      PhysicalDocument: filterDataDelivery.isPhysicalDocument,
      StatusDelivery: filterDataDelivery.statusDelivery,
      Search: filterDataDelivery.search,
      page: value,
    };
    mutateDeliveryData(data);
  };

  const handleDownload = () => {
    const data = {
      timeStart: filterDataDelivery.periodeDelivery[0].format('YYYYMMDD'),
      timeEnd: filterDataDelivery.periodeDelivery[1].format('YYYYMMDD'),
      customer: filterDataDelivery.customer || 0,
      product: filterDataDelivery.customerProduct || 0,
      branch: filterDataDelivery.branch || 0,
      courier: filterDataDelivery.courier || 0,
      deliveryTime: filterDataDelivery.typePeriode,
      deliveryStatus: filterDataDelivery.statusDelivery.join(','),
      typeReportVendor: selectedDownloadtype,
      physicalDocument: filterDataDelivery.isPhysicalDocument,
    };
    mutateDownloadSingle(data);
  };

  const disableDownloadButton =
    !selectedDownloadtype ||
    !filterDataDelivery.periodeDelivery ||
    filterDataDelivery.periodeDelivery.length !== 2 ||
    !filterDataDelivery.periodeDelivery[0] ||
    !filterDataDelivery.periodeDelivery[1];

  return (
    <div style={{ padding: '35px' }}>
      <div style={{ paddingBottom: '35px' }}>
        <FilterDataDeliveryComponent
          showChangeFilter={showChangeFilter}
          filterDataDelivery={filterDataDelivery}
          setShowChangeFilter={setShowChangeFilter}
          handleChangeFilter={handleChangeFilter}
          handleResetFilter={handleResetFilter}
          handleSubmitFilter={handleSubmitFilter}
          dataCustomers={dataCustomers}
          dataProducts={dataProducts}
          dataBranchs={dataBranchs}
          dataCourier={dataCourier}
          isLoadingCustomers={isLoadingCustomers}
          isLoadingProducts={isLoadingProducts}
          isLoadingBranch={isLoadingBranch}
          isLoadingCourier={isLoadingCourier}
          isLoading={isLoadingMutate || isLoadingCount}
        />
      </div>
      <div>
        <TableDataDeliveryComponent
          isLoading={isLoadingMutate || isLoadingCount}
          dataTableDelivery={dataTableDelivery}
          selectedDownloadtype={selectedDownloadtype}
          setSelectedDownloadType={setSelectedDownloadType}
          handleSearchName={handleSearchName}
          handlePage={handlePage}
          handleDownload={handleDownload}
          disableDownloadButton={disableDownloadButton}
          dataDeliveryCount={dataDeliveryCount}
        />
      </div>
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
    </div>
  );
}

const mapStateProps = (state: any) => ({
  IdBranch: state.Auth.BranchAuth,
});

const mapDispatchToProps = (dispatch: any) => ({});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(Index);
