import * as ActionCourierTable from '../../../core/action/ManageAccount/Courier/ActionTableCourier';
import * as ActionEmergency from '../../../core/action/ManualProcess/ActionEmergencyProcess';
import * as ActionLastAccess from '../../../core/action/LastAccess/index';
import * as ActionListCustomer from '../../../core/action/Customer/ActionListCustomer';
import * as ActionManualProcess from '../../../core/action/ManualProcess/ActionManualProcess';
import * as ActionManualProcessVendor from '../../../core/action/ManualProcess/ActionManualProcessVendor';
import * as ActionUpdateAddress from '../../../core/action/ManualProcess/ActionUpdateAddress';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import IndexManualProcessComponent from '../../../components/Content/ManualProcess/IndexManualProcessComponent';
import { connect } from 'react-redux';

interface IProps {
  actionListCustomer: any;
  actionEmergency: any;
  actionLastAccess: any;
  actionCourierTable: any;
  actionManualProcessVendor: any;
  actionManualProcess: any;
  actionUpdateAddress: any;
  Fiture: any;
  sideMenus: any;
}

class IndexManualProcessContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);

    this.handleChangeTabs = this.handleChangeTabs.bind(this);
  }

  handleChangeTabs(event: any) {
    this.props.actionListCustomer.clearListCustomer();
    this.props.actionEmergency.ClearAllEmergancyForm();
    this.props.actionCourierTable.clearCourierTable();
    if (event === '1') {
      this.props.actionLastAccess.changeLastAccessFiture('deliveryManual');
      this.props.actionEmergency.clearCourierAndBranchEmergencyForm();
      this.props.actionManualProcessVendor.clearCourierAndBranchVendorForm();
      this.props.actionManualProcessVendor.clearManualProcessVendorForm();
      this.props.actionUpdateAddress.clearAllForm();
    } else if (event === '2') {
      this.props.actionLastAccess.changeLastAccessFiture('deliveryVendor');
      this.props.actionEmergency.clearCourierAndBranchEmergencyForm();
      this.props.actionManualProcess.clearCourierAndBranchForm();
      this.props.actionUpdateAddress.clearAllForm();
    } else if (event === '3') {
      this.props.actionLastAccess.changeLastAccessFiture('deliveryEmergency');
      this.props.actionManualProcessVendor.clearCourierAndBranchVendorForm();
      this.props.actionManualProcess.clearCourierAndBranchForm();
      this.props.actionManualProcessVendor.clearManualProcessVendorForm();
      this.props.actionUpdateAddress.clearAllForm();
    } else if (event === '4') {
      this.props.actionLastAccess.changeLastAccessFiture(
        'deliveryUpdateAddress'
      );
      this.props.actionEmergency.clearCourierAndBranchEmergencyForm();
      this.props.actionManualProcessVendor.clearCourierAndBranchVendorForm();
      this.props.actionManualProcessVendor.clearManualProcessVendorForm();
    }
  }

  render() {
    return (
      <IndexManualProcessComponent
        lastAccessFiture={this.props.Fiture}
        handleChangeTabs={this.handleChangeTabs}
        sideMenus={this.props.sideMenus}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  Fiture: state.LastAccess.Fiture,
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionEmergency: bindActionCreators(ActionEmergency, dispatch),
  actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
  actionCourierTable: bindActionCreators(ActionCourierTable, dispatch),
  actionManualProcessVendor: bindActionCreators(
    ActionManualProcessVendor,
    dispatch
  ),
  actionManualProcess: bindActionCreators(ActionManualProcess, dispatch),
  actionUpdateAddress: bindActionCreators(ActionUpdateAddress, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexManualProcessContainer);
