import React from 'react';
import {
  HomeIcon,
  PickUpIcon,
  DeliveryIcon,
  ReportIcon,
  ManifestIcon,
  CustomerIcon,
  BranchIcon,
  ComplaintIcon,
  ManageAccountIcon,
  HolidayIcon,
  VoipIcon,
  DeleteIcon,
  SmsBlastIcon,
  LogIcon,
  SettingLocation,
} from './iconSidebarSvg';

export const districtCode = [
  {
    name: 'Jabodetabek',
    id: 'Jabodetabek',
  },
  {
    name: 'Jawa',
    id: 'Jawa',
  },
  {
    name: 'Luar Jawa',
    id: 'LuarJawa',
  },
  {
    name: 'Indonesia Timur',
    id: 'IndonesiaTimur',
  },
  {
    name: 'Jakarta',
    id: 'Jakarta',
  },
  {
    name: 'Bodetabek',
    id: 'Bodetabek',
  },
  {
    name: 'Kalimantan dan Sumatra',
    id: 'KalimantanDanSumatra',
  },
  {
    name: 'Sulawesi Maluku dan Bali',
    id: 'SulawesiMalukuDanBali',
  },
  {
    name: 'Papua NTT dan NTB',
    id: 'PapuaNTTDanNTB',
  },
  {
    name: 'Sumkal',
    id: 'Sumkal',
  },
  {
    name: 'Sumapa',
    id: 'Sumapa',
  },
  {
    name: 'Balinusa',
    id: 'Balinusa',
  },
];

export const paperTypes = [
  {
    name: 'Standar',
    id: 'Standar',
  },
  {
    name: 'A4',
    id: 'A4',
  },
  {
    name: 'A5',
    id: 'A5',
  },
];

export const deliveryTypes = [
  {
    name: 'Urgent',
    id: 'Urgent',
  },
  {
    name: 'SDS',
    id: 'SameDayService',
  },
  {
    name: 'Reguler',
    id: 'Reguler',
  },
];

export const fileFormat = [
  {
    id: 'xls',
    name: 'EXCEL',
  },
  {
    id: 'csv',
    name: 'CSV',
  },
];

export const treatmentDocument = [
  { id: 'ReturnCustomer', name: 'Dikembalikan ke Pelanggan' },
  { id: 'Destroy', name: 'Dihancurkan' },
];

export const statusDelivery = [
  { id: 'Success', name: 'Diterima' },
  { id: 'Return', name: 'Dikembalikan' },
  { id: 'OnProcces', name: 'Proses' },
  { id: 'OnHold', name: 'OnHold' },
];

export const accountTypes = [
  { id: 'Admin', name: 'Admin Pusat' },
  { id: 'Branch', name: 'Admin Posko' },
];

export const archiveDateTypes = [
  { id: 'Process', name: 'Upload' },
  { id: 'Cycle', name: 'Cycle' },
];

export const productTypes = [
  { id: 'Card', name: 'Kartu' },
  { id: 'Policy', name: 'Polis' },
  { id: 'Letter', name: 'Surat' },
  { id: 'Others', name: 'Lainnya' },
];

export const iconSidebar = [
  {
    key: 'home',
    icon: (color?: string) => <HomeIcon color={color} />,
  },
  {
    key: 'pickup',
    icon: (color?: string) => <PickUpIcon color={color} />,
  },
  {
    key: 'delivery',
    icon: (color?: string) => <DeliveryIcon color={color} />,
  },
  {
    key: 'report',
    icon: (color?: string) => <ReportIcon color={color} />,
  },
  {
    key: 'manifest',
    icon: (color?: string) => <ManifestIcon color={color} />,
  },
  {
    key: 'customer',
    icon: (color?: string) => <CustomerIcon color={color} />,
  },
  {
    key: 'branch',
    icon: (color?: string) => <BranchIcon color={color} />,
  },
  {
    key: 'complaint',
    icon: (color?: string) => <ComplaintIcon color={color} />,
  },
  {
    key: 'manageAccount',
    icon: (color?: string) => <ManageAccountIcon color={color} />,
  },
  {
    key: 'settingLocation',
    icon: (color?: string) => <SettingLocation color={color} />,
  },
  {
    key: 'holiday',
    icon: (color?: string) => <HolidayIcon color={color} />,
  },
  {
    key: 'voip',
    icon: (color?: string) => <VoipIcon color={color} />,
  },
  {
    key: 'smsBlast',
    icon: (color?: string) => <SmsBlastIcon color={color} />,
  },
  {
    key: 'restore',
    icon: (color?: string) => <DeleteIcon color={color} />,
  },
  {
    key: 'logActivity',
    icon: (color?: string) => <LogIcon color={color} />,
  },
];

export const sidebarDatas = [
  {
    link: '/',
    key: '/',
    icon: 'home',
    label: 'Beranda',
    subMenus: null,
    keySubmenus: null,
    tabMenus: null,
  },
  {
    link: '',
    key: '/pickup',
    icon: 'pickup',
    label: 'Pengambilan',
    subMenus: [
      {
        label: 'Sinkronisasi Data',
        link: '/processPickUp',
      },
      {
        label: 'Status Pengambilan',
        link: '/pickupStatus',
      },
      {
        label: 'Data Pengambilan',
        link: '/pickupData',
      },
    ],
    keySubmenus: ['/processPickUp', '/pickupStatus', '/pickupData'],
  },
  {
    link: '',
    key: '/delivery',
    icon: 'delivery',
    label: 'Pengiriman',
    subMenus: [
      {
        label: 'Status Pengiriman',
        link: '/delivery/status',
      },
      {
        label: 'Data Pengiriman',
        link: '/dataDelivery',
      },
      {
        label: 'Document',
        link: '/delivery/document',
      },
      {
        label: 'Kirim Ulang',
        link: '/delivery/uploadSoftcopy',
      },
      {
        label: 'Penugasan Kurir',
        link: '/assigningCourier',
      },
      {
        label: 'Attempt',
        link: '/resend',
      },
      {
        label: 'Proses Manual',
        link: '/manualProcess',
      },
      {
        label: 'Kiriman Prioritas',
        link: '/deliveryPriority',
      },
      {
        label: 'Call Center',
        link: '/callCenter',
      },
      {
        label: 'Pengiriman Antar Posko',
        link: '/deliveryBetweenPosts',
      },
    ],
    keySubmenus: [
      '/delivery/status',
      '/dataDelivery',
      '/delivery/document',
      '/delivery/uploadSoftcopy',
      '/assigningCourier',
      '/resend',
      '/manualProcess',
      '/deliveryPriority',
      '/callCenter',
      '/deliveryBetweenPosts',
    ],
  },
  {
    link: '',
    key: '/laporan',
    icon: 'report',
    label: 'Laporan',
    subMenus: [
      {
        label: 'Download',
        link: '/laporan/download',
      },
      {
        label: 'Ringkasan',
        link: '/laporan/ringkasan',
      },
    ],
    keySubmenus: ['/laporan/download', '/laporan/ringkasan'],
  },
  {
    link: '/IndexManifest',
    key: '/IndexManifest',
    icon: 'manifest',
    label: 'Manifest',
    subMenus: null,
    keySubmenus: null,
  },
  {
    link: '/customer',
    key: '/customer',
    icon: 'customer',
    label: 'Pelanggan',
    subMenus: null,
    keySubmenus: ['/customer/config', '/customer/detail', '/customer'],
  },
  {
    link: '/branch',
    key: '/branch',
    icon: 'branch',
    label: 'Posko',
    subMenus: null,
    keySubmenus: ['/branch', '/branch/detail'],
  },
  {
    link: '/complaintNew',
    key: '/complaintNew',
    icon: 'complaint',
    label: 'Keluhan',
    subMenus: null,
    keySubmenus: null,
  },
  {
    link: '',
    key: '',
    icon: 'manageAccount',
    label: 'Pengaturan Akun',
    subMenus: [
      {
        label: 'Akun',
        link: '/manageAccount',
      },
      {
        label: 'Level Grup Akses',
        link: '/manageLevelAccount',
      },
      {
        label: 'Role',
        link: '/role',
      },
    ],
    keySubmenus: [
      '/manageAccount',
      '/manageLevelAccount',
      '/role',
      '/manageLevelAccount/addLevelAccount',
      '/role/addRole',
    ],
  },
  {
    link: '/setting-location',
    key: '/setting-location',
    icon: 'settingLocation',
    label: 'Pengaturan Wilayah',
    subMenus: null,
    keySubmenus: null,
  },
  {
    link: '/holiday',
    key: '/holiday',
    icon: 'holiday',
    label: 'Hari Libur',
    subMenus: null,
    keySubmenus: null,
  },
  {
    link: '/voip',
    key: '/voip',
    icon: 'voip',
    label: 'Voip',
    subMenus: null,
    keySubmenus: null,
  },
  {
    link: '/sms-blast',
    key: '/sms-blast',
    icon: 'smsBlast',
    label: 'SMS Blast',
    subMenus: null,
    keySubmenus: null,
  },
  {
    link: '/restore',
    key: '/restore',
    icon: 'restore',
    label: 'Hapus Data',
    subMenus: null,
    keySubmenus: null,
  },
  {
    link: '/logActivity',
    key: '/logActivity',
    icon: 'logActivity',
    label: 'Log Activity',
    subMenus: null,
    keySubmenus: null,
  },
];

export const BagTypes = [
  { id: 'Delivery', name: 'Pengiriman' },
  { id: 'Return', name: 'Return' },
];

export const BagStatus = [
  { id: 'Process', name: 'Proses' },
  { id: 'Success', name: 'Selesai' },
];

export const TypeDashboardPerformance = [
  { id: '0', name: 'Semua Posko' },
  { id: '1', name: 'Posko Internal' },
  { id: '2', name: 'Posko Vendor' },
];

export const DownloadBagTypes = [{ id: 'letter', name: 'Barcode Bag (.pdf)' }];

export const DownloadReportSLATypes = [{ id: 'excel', name: 'EXCEL (.xlsx)' }];

export const levelGroupDummy = [
  {
    id: 1,
    menuName: 'Beranda',
    key: '0',
    subMenu: false,
    tabMenu: false,
    link: '/',
    icon: 'home',
    position: 0,
    permission: { view: false },
    keys: null,
    subMenus: [],
    tabMenus: [],
  },
  {
    id: 2,
    menuName: 'Pengambilan',
    key: '1',
    subMenu: true,
    tabMenu: false,
    link: '',
    icon: 'pickup',
    position: 0,
    permission: { view: false },
    keys: ['/processPickUp', '/pickupStatus', '/pickupData'],
    subMenus: [
      {
        id: 8,
        menuName: 'Sinkronisasi Data',
        key: '1-0',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [],
      },
      {
        id: 9,
        menuName: 'Status Pengambilan',
        key: '1-1',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [],
      },
      {
        id: 10,
        menuName: 'Data Pengambilan',
        key: '1-2',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [],
      },
    ],
    tabMenus: [],
  },
  {
    id: 3,
    menuName: 'Pegiriman',
    key: '2',
    subMenu: true,
    tabMenu: true,
    link: '',
    icon: 'delivery',
    position: 0,
    permission: { view: false },
    keys: [
      '/delivery/status',
      '/dataDelivery',
      '/delivery/document',
      '/delivery/uploadSoftcopy',
      '/assigningCourier',
      '/resend',
      '/manualProcess',
      '/deliveryBetweenPosts',
      '/deliveryPriority',
      '/callCenter',
    ],
    subMenus: [
      {
        id: 11,
        menuName: 'Status Pengiriman',
        key: '2-0',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [],
      },
      {
        id: 12,
        menuName: 'Data Pengiriman',
        key: '2-1',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [],
      },
      {
        id: 13,
        menuName: 'Dokumen',
        key: '2-2',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [
          {
            id: null,
            menuName: 'Unggah Data',
            key: '2-2-1',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Distribusi',
            key: '2-2-2',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Cetak Waybill',
            key: '2-2-3',
            position: 0,
            permission: { view: false },
          },
        ],
      },
      {
        id: 14,
        menuName: 'Kirim Ulang',
        key: '2-3',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [
          {
            id: null,
            menuName: 'Unggah Data',
            key: '2-3-1',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Satuan',
            key: '2-3-2',
            position: 0,
            permission: { view: false },
          },
        ],
      },
      {
        id: 15,
        menuName: 'Penugasan Kurir',
        key: '2-4',
        link: null,
        position: 0,
        permission: { view: false },
      },
      {
        id: 16,
        menuName: 'Attempt',
        key: '2-5',
        link: null,
        position: 0,
        permission: { view: false },
      },
      {
        id: 17,
        menuName: 'Proses Manual',
        key: '2-6',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [
          {
            id: null,
            menuName: 'Scan Selesai',
            key: '2-6-1',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Unggah Selesai',
            key: '2-6-2',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Emergency',
            key: '2-6-3',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Perbaharui Alamat',
            key: '2-6-4',
            position: 0,
            permission: { view: false },
          },
        ],
      },
      {
        id: 18,
        menuName: 'Kiriman Prioritas',
        key: '2-7',
        link: null,
        position: 0,
        permission: { view: false },
      },
      {
        id: 19,
        menuName: 'Call Center',
        key: '2-8',
        link: null,
        position: 0,
        permission: { view: false },
      },
      {
        id: 23,
        menuName: 'Pengiriman Antar Posko',
        key: '2-9',
        link: null,
        position: 0,
        permission: { view: false },
      },
    ],
    tabMenus: [],
  },
  {
    id: null,
    menuName: 'Laporan',
    key: '3',
    subMenu: true,
    tabMenu: true,
    link: '',
    icon: 'report',
    position: 0,
    permission: { view: false },
    keys: ['/laporan/download', '/laporan/ringkasan'],
    tabMenus: null,
    subMenus: [
      {
        id: null,
        menuName: 'Download',
        key: '3-1',
        link: '/laporan/download',
        position: 0,
        permission: { view: false },
        tabMenus: [
          {
            id: null,
            menuName: 'Laporan Kurir',
            key: '3-1-1',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Laporan Pengiriman',
            key: '3-1-2',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Faktur',
            key: '3-1-3',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Laporan Pelanggan',
            key: '3-1-4',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'MIS',
            key: '3-1-5',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Insentif Kurir',
            key: '3-1-6',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Laporan Validasi',
            key: '3-1-7',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Laporan Keluhan',
            key: '3-1-8',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Waybill Digital',
            key: '3-1-9',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'BAST SMS',
            key: '3-1-10',
            position: 0,
            permission: { view: false },
          },
        ],
      },
      {
        id: null,
        menuName: 'Ringkasan',
        key: '3-2',
        link: '/laporan/ringkasan',
        position: 0,
        permission: { view: false },
        tabMenus: [
          {
            id: null,
            menuName: 'SLA Pengiriman',
            key: '3-2-1',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Jam Pengiriman',
            key: '3-2-2',
            position: 0,
            permission: { view: false },
          },
        ],
      },
    ],
  },
  {
    id: 5,
    menuName: 'Manifest',
    key: '4',
    subMenu: false,
    tabMenu: true,
    link: '/IndexManifest',
    icon: 'manifest',
    position: 0,
    permission: { view: false },
    keys: null,
    subMenus: [],
    tabMenus: [
      {
        id: null,
        menuName: 'Manifest Kurir',
        key: '4-1',
        position: 0,
        permission: { view: false },
      },
      {
        id: null,
        menuName: 'Scan Manifest',
        key: '4-2',
        position: 0,
        permission: { view: false },
      },
      {
        id: null,
        menuName: 'Cetak Manifest',
        key: '4-3',
        position: 0,
        permission: { view: false },
      },
    ],
  },
  {
    id: 6,
    menuName: 'Pelanggan',
    key: '5',
    subMenu: false,
    tabMenu: false,
    link: '/customer',
    icon: 'customer',
    position: 0,
    permission: { view: false },
    keys: ['/customer/config', '/customer/detail', '/customer'],
    subMenus: [],
    tabMenus: [],
  },
  {
    id: 7,
    menuName: 'Posko',
    key: '6',
    subMenu: false,
    tabMenu: false,
    link: '/branch',
    icon: 'branch',
    position: 0,
    permission: { view: false },
    keys: ['/branch', '/branch/detail'],
    subMenus: [],
    tabMenus: [],
  },
  {
    id: 8,
    menuName: 'Keluhan',
    key: '7',
    subMenu: false,
    tabMenu: false,
    link: '/complaintNew',
    icon: 'complaint',
    position: 0,
    permission: { view: false },
    keys: null,
    subMenus: [],
    tabMenus: [],
  },
  {
    id: 9,
    menuName: 'Pengaturan Akun',
    key: '8',
    subMenu: true,
    tabMenu: true,
    link: '',
    icon: 'manageAccount',
    position: 0,
    permission: { view: false },
    keys: [
      '/manageAccount',
      '/manageLevelAccount',
      '/role',
      '/manageLevelAccount/addLevelAccount',
      '/role/addRole',
    ],
    subMenus: [
      {
        id: 20,
        menuName: 'Akun',
        key: '8-0',
        link: null,
        position: 0,
        permission: { view: false },
        tabMenus: [
          {
            id: null,
            menuName: 'Admin',
            key: '8-0-1',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Kurir',
            key: '8-0-2',
            position: 0,
            permission: { view: false },
          },
          {
            id: null,
            menuName: 'Pelanggan',
            key: '8-0-3',
            position: 0,
            permission: { view: false },
          },
        ],
      },
      {
        id: 21,
        menuName: 'Level Grup Akses',
        key: '8-1',
        link: null,
        position: 0,
        permission: { view: false },
      },
      {
        id: 22,
        menuName: 'Role',
        key: '8-2',
        link: null,
        position: 0,
        permission: { view: false },
      },
    ],
    tabMenus: [],
  },
  {
    id: 10,
    menuName: 'Hari Libur',
    key: '9',
    subMenu: false,
    tabMenu: false,
    link: '/holiday',
    icon: 'holiday',
    position: 0,
    permission: { view: false },
    keys: null,
    tabMenus: [],
  },
  {
    id: 11,
    menuName: 'Voip',
    key: '10',
    subMenu: false,
    tabMenu: false,
    link: '/voip',
    icon: 'voip',
    position: 0,
    permission: { view: false },
    keys: null,
    subMenus: [],
    tabMenus: [],
  },
  {
    id: 12,
    menuName: 'Log Activity',
    key: '11',
    subMenu: false,
    tabMenu: false,
    link: '/logActivity',
    icon: 'logActivity',
    position: 0,
    permission: { view: false },
    keys: null,
    subMenus: [],
    tabMenus: [],
  },
  {
    id: 13,
    menuName: 'Hapus Data',
    key: '12',
    subMenu: false,
    tabMenu: true,
    link: '/restore',
    icon: 'restore',
    position: 0,
    permission: { view: false },
    keys: null,
    subMenus: [],
    tabMenus: [
      {
        id: null,
        menuName: 'CSV',
        key: '12-1',
        position: 0,
        permission: { view: false },
      },
      {
        id: null,
        menuName: 'Waybill',
        key: '12-2',
        position: 0,
        permission: { view: false },
      },
      {
        id: null,
        menuName: 'Pelanggan',
        key: '12-3',
        position: 0,
        permission: { view: false },
      },
    ],
  },
  {
    id: 14,
    menuName: 'SMS Blast',
    key: '13',
    subMenu: false,
    tabMenu: true,
    link: '/sms-blast',
    icon: 'smsBlast',
    position: 0,
    permission: { view: false },
    keys: null,
    subMenus: [],
    tabMenus: [
      {
        id: null,
        menuName: 'Log Gagal',
        key: '13-1',
        position: 0,
        permission: { view: false },
      },
      {
        id: null,
        menuName: 'Notifikasi Email',
        key: '13-2',
        position: 0,
        permission: { view: false },
      },
    ],
  },
];

export const MANIFEST_BRANKAS_DOWNLOAD_TYPE = {
  Scan: 'Scan',
  UnScan: 'UnScan',
  Total: 'Total',
  ScanUser: 'ScanUser',
};

export const PERFORMANCE_POSKO_INDICATOR = {
  totalInbound: 'Total Inbound',
  successSLA: 'Total Aging < = 3',
  totalSuccess: 'Total Succes',
  totalReturn: 'Total Retur',
  totalInProcess: 'Total In Process',
  totalSuccessByPodDate: 'Total Succes by POD date',
  totalReturnByPodDate: 'Total Return by POD date',
  totalSuccesPod: 'Total Succes POD D+0',
  overallSLA: 'Overall SLA %',
  successRate: 'Succes Rate %',
  productivity: 'Productivity',
  returnRate: 'Return Rate %',
  hitRate: 'Hit Rate %',
};
