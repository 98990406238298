import * as ActionDownloadCustomerReport from 'core/action/Report/CustomerReport/ActionCustomerReportForm';
import * as ActionListCustomer from 'core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from 'core/action/CustomerProduct/ActionListCustomerProduct';
import * as ActionListReportFormat from 'core/action/Customer/ActionListCustomerReportFormat';
import * as ActionResetListReportFormat from 'core/action/Customer/ActionDetailConfigReport';
import * as LastAccess from 'core/action/LastAccess/index';

import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { useMutation } from '@tanstack/react-query';
import { downloadCustomerReport } from 'services/ReportCustomerServices';
import { processProgressRequest, downloadBlob } from 'lib/util';
import ModalStatus from 'materials/ModalStatus';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';

import CustomerReportComponent from 'module/Report/Component/CustomerReport/CustomerReportComponent';
import { connect } from 'react-redux';
import moment from 'moment';
import { Modal } from 'antd';

interface IProps {
  isLoading: any;
  actionLastAccess: any;
  actionCustomerReport: any;
  actionListCustomer: any;
  actionListCustomerProduct: any;
  actionListReportFormat: any;
  actionResetListReportFormat: any;
  RadioButton: any;
  DatePickerMonth: any;
  PickupDate: any;
  Customer: any;
  CustomerProduct: any;
  ReportFormat: any;
  DeliveryStatus: any;
  TreatmentDocument: any;
  StatusSuccess: any;
  StatusReturn: any;
  TypeDelivery: any;
  FileData: any;
  IndexColumn: any;
  CheckBoxBarcode: any;
  CheckBoxFileName: any;
  CheckBoxFullData: any;
  CheckBoxToDayData: any;
  ListCustomer: any;
  ListCustomerProduct: any;
  ListReportFormat: any;
  ListStatusSuccess: any;
  ListStatusReturn: any;
  LoadingSelectCustomer: any;
  LoadingSelectCustomerProduct: any;
  LoadingSelectReportFormat: any;
  FormatReport: any;
  CustomerName: any;
  CustomerId: any;
  CustomerProductId: any;
}

function CustomerReportContainer({
  isLoading,
  actionLastAccess,
  actionCustomerReport,
  actionListCustomer,
  actionListCustomerProduct,
  actionListReportFormat,
  actionResetListReportFormat,
  RadioButton,
  DatePickerMonth,
  PickupDate,
  Customer,
  CustomerProduct,
  ReportFormat,
  DeliveryStatus,
  TreatmentDocument,
  StatusSuccess,
  StatusReturn,
  TypeDelivery,
  FileData,
  IndexColumn,
  CheckBoxBarcode,
  CheckBoxFileName,
  CheckBoxFullData,
  CheckBoxToDayData,
  ListCustomer,
  ListCustomerProduct,
  ListReportFormat,
  ListStatusSuccess,
  ListStatusReturn,
  LoadingSelectCustomer,
  LoadingSelectCustomerProduct,
  LoadingSelectReportFormat,
  FormatReport,
  CustomerName,
  CustomerId,
  CustomerProductId,
}: IProps) {
  const [ValuePickupDate, setValuePickupDate]: any = useState(null);
  const [ValidatePickupDate, setValidatePickupDate]: any = useState(null);
  const [ValidateConditionPickupDate, setValidateConditionPickupDate]: any =
    useState(null);
  const [ValidateCustomer, setValidateCustomer]: any = useState(null);
  const [ValidateCustomerProduct, setValidateCustomerProduct]: any =
    useState(null);
  const [ValidateReportFormat, setValidateReportFormat]: any = useState(null);
  const [ValidateDeliveryStatus, setValidateDeliveryStatus]: any =
    useState(null);
  const [ValidateTreatmentDocument, setValidateTreatmentDocument]: any =
    useState(null);
  const [ValidateStatusSuccess, setValidateStatusSuccess]: any = useState(null);
  const [ValidateStatusReturn, setValidateStatusReturn]: any = useState(null);
  const [ValidateTypeDelivery, setValidateTypeDelivery]: any = useState(null);
  const [ValidateColumn, setValidateColumn]: any = useState(null);
  const [collapse, setcollapse]: any = useState(false);
  const [file, setfile]: any = useState(null);
  const [fileName, setfileName]: any = useState('');

  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);

  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation((data: any) => downloadCustomerReport(data, progressHandler), {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          const formats = {
            EXCEL: 'xlsx',
            CSV: 'csv',
            TXT: 'txt',
          };
          const formatFile = formats[FormatReport.toUpperCase()] || '';
          const Month = DatePickerMonth === true ? 'Bulanan' : 'Harian';
          const DateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
          const baseFileName = `Laporan ${CustomerName} ${Month} ${PickupDate} ${DateNow}`;

          downloadBlob(
            res.data,
            baseFileName,
            CheckBoxFileName ? 'zip' : formatFile
          );
          ModalStatus({
            status: 'success',
            title: 'Download berhasil',
          });

          actionResetListReportFormat.changeFormatReport(null);
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  useEffect(() => {
    if (FormatReport) {
      handleDownloadReport();
    }

    // eslint-disable-next-line
  }, [FormatReport]);

  const handleDownloadReport = () => {
    const Month = DatePickerMonth === true ? 'Bulanan' : 'Harian';
    const pickupDate = moment(PickupDate).format(
      Month === 'Bulanan' ? 'YYYYMM' : 'YYYYMMDD'
    );
    const checkStatusSuccess = DeliveryStatus.filter(
      (item: any) => item === 'Success'
    );
    const checkStatusReturn = DeliveryStatus.filter(
      (item: any) => item === 'Return'
    );

    const data = {
      customerId: CustomerId,
      customReportFileId: ReportFormat,
      filterDateType: RadioButton,
      date: pickupDate,
      month: DatePickerMonth,
      customerProducts: CustomerProductId,
      deliveryStats: DeliveryStatus,
      treatmentDocument: TreatmentDocument,
      fileString: FileData || '',
      coloumn: IndexColumn || 0,
      trueOrFalse: CheckBoxBarcode,
      statusSuccess: checkStatusSuccess.length > 0 ? StatusSuccess : [],
      statusReturn: checkStatusReturn.length > 0 ? StatusReturn : [],
      deliveryType: RadioButton === 'Manifest' ? [] : TypeDelivery,
      groupFileName: CheckBoxFileName,
      deliveryDetail: CheckBoxFullData,
      today: CheckBoxToDayData,
    };

    mutateDownloadFile(data);
  };

  function handleRadioButton(event: any) {
    if (event.target.value === 'Manifest') {
      handlePutDeliveryStatus(['Success', 'Return']);
      handlePutStatusSuccess([]);
      handlePutStatusReturn([]);
    } else {
      if (RadioButton === 'Manifest') {
        actionCustomerReport.changeCustomerProductIdCustomerReport([]);
        actionCustomerReport.changeCustomerIdCustomerReport(null);
        actionCustomerReport.changeCustomerNameCustomerReport(null);
      }
    }
    actionCustomerReport.changeRadioButtonCustomerReport(event.target.value);
  }

  function handleDatePickerMonth() {
    actionCustomerReport.changeDatePickerMonthCustomerReport();
  }

  function handleValidatePickupDate(event: any) {
    setValidateConditionPickupDate(event);
    if (ValidateConditionPickupDate === true) {
      if (
        PickupDate === null ||
        PickupDate === undefined ||
        PickupDate === 'Invalid date'
      ) {
        setValidatePickupDate('error');
      } else {
        setValidatePickupDate(null);
      }
    } else {
    }
  }

  function handlePutPickupDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      actionCustomerReport.changePickupDateCustomerReport(dateValue);
    } else {
      actionCustomerReport.changePickupDateCustomerReport(null);
    }
    setValuePickupDate(event);
    if (event === '' || event === null) {
      setValidatePickupDate('error');
    } else {
      setValidatePickupDate(null);
    }
  }

  function handleResetCustomer() {
    actionListCustomerProduct.clearListCustomerProduct();
    actionCustomerReport.clearCustomerIdCustomerReportForm();
    actionCustomerReport.clearReportFormatCustomerReportForm();
    setValidateReportFormat(null);
  }

  function handleGetCustomer() {
    actionListCustomer.getListCustomer();
  }

  function handlePutCustomer(event: any, label: any) {
    actionCustomerReport.changeCustomerIdCustomerReport(event);
    actionCustomerReport.changeCustomerNameCustomerReport(label.props.label);
    if (event === null) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }

    if (RadioButton !== 'Manifest') {
      actionCustomerReport.getStatusDeliverySuccessCustomerReport();
      actionCustomerReport.getStatusDeliveryReturnCustomerReport();
    }
  }

  function handleBlurCustomer() {
    if (Customer === null) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }

  function handleGetCustomerProduct() {
    actionListCustomerProduct.getListCustomerProductNoType();
  }

  function handleBlurCustomerProduct() {
    if (CustomerProduct.length === undefined || CustomerProduct.length < 0) {
      setValidateCustomerProduct('error');
    } else {
      setValidateCustomerProduct(null);
    }
  }

  function handlePutCustomerProduct(event: any) {
    const searchSelectAll = event.toString().search('SetAllProduct');
    if (searchSelectAll >= 0) {
      actionCustomerReport.changeCustomerProductIdCustomerReport(
        ListCustomerProduct.map((data: any, index: any) => data.id)
      );
    } else {
      actionCustomerReport.changeCustomerProductIdCustomerReport(event);
    }

    if (CustomerProduct === null || event.length === 0) {
      setValidateCustomerProduct('error');
    } else {
      setValidateCustomerProduct(null);
    }
  }

  function handleGetReportFormat() {
    actionListReportFormat.getListCustomerReportFormat();
  }

  function handleBlurReportFormat() {
    if (ReportFormat === null) {
      ValidateReportFormat('error');
    } else {
      setValidateReportFormat(null);
    }
  }

  function handlePutReportFormat(event: any) {
    const selectedFormatReport = ListReportFormat.find(
      value => value.id === event
    );
    const requestPhoneNumberReport =
      selectedFormatReport?.customReportType === 'CustomRequestPhoneNumber' ||
      false;

    if (RadioButton !== 'Manifest') {
      if (requestPhoneNumberReport) {
        handlePutDeliveryStatus(['Return']);
        handlePutStatusSuccess([]);
      } else {
        handlePutDeliveryStatus(['OnProcces', 'Success', 'Return']);
      }
    }

    actionCustomerReport.changeReportFormatCustomerReport(event);

    if (event === null) {
      setValidateReportFormat('error');
    } else {
      setValidateReportFormat(null);
    }
  }

  function handleBlurDeliveryStatus() {
    if (DeliveryStatus === null || DeliveryStatus.length === 0) {
      setValidateDeliveryStatus('error');
    } else {
      setValidateDeliveryStatus(null);
    }
  }

  function handlePutDeliveryStatus(event: any) {
    actionCustomerReport.changeDeliveryStatusCustomerReport(event);

    if (event === null || event.length === 0) {
      setValidateDeliveryStatus('error');
    } else {
      setValidateDeliveryStatus(null);
    }
  }

  function handleBlurTreatmentDocument() {
    if (TreatmentDocument === null || TreatmentDocument.length === 0) {
      setValidateTreatmentDocument('error');
    } else {
      setValidateTreatmentDocument(null);
    }
  }

  function handlePutTreatmentDocument(event: any) {
    actionCustomerReport.changeTreatmentDocumentCustomerReport(event);

    if (event === null || event.length === 0) {
      setValidateTreatmentDocument('error');
    } else {
      setValidateTreatmentDocument(null);
    }
  }

  function handleBlurStatusSuccess() {
    if (StatusSuccess === null || StatusSuccess.length === 0) {
      setValidateStatusSuccess('error');
    } else {
      setValidateStatusSuccess(null);
    }
  }

  function handlePutStatusSuccess(event: any) {
    const searchSelectAll = event.toString().search('SetAllStatus');
    if (searchSelectAll >= 0) {
      actionCustomerReport.changeStatusSuccessCustomerReport(
        ListStatusSuccess.map((data: any) => data)
      );
    } else {
      actionCustomerReport.changeStatusSuccessCustomerReport(event);
    }

    if (event === null || event.length === 0) {
      setValidateStatusSuccess('error');
    } else {
      setValidateStatusSuccess(null);
    }
  }

  function handleBlurStatusReturn() {
    if (StatusReturn === null || StatusReturn.length === 0) {
      setValidateStatusReturn('error');
    } else {
      setValidateStatusReturn(null);
    }
  }

  function handlePutStatusReturn(event: any) {
    const searchSelectAll = event.toString().search('SetAllStatus');
    if (searchSelectAll >= 0) {
      actionCustomerReport.changeStatusReturnCustomerReport(
        ListStatusReturn.map((data: any) => data)
      );
    } else {
      actionCustomerReport.changeStatusReturnCustomerReport(event);
    }

    if (event === null || event.length === 0) {
      setValidateStatusReturn('error');
    } else {
      setValidateStatusReturn(null);
    }
  }

  function handleBlurTypeDelivery() {
    if (TypeDelivery === null || TypeDelivery.length === 0) {
      setValidateTypeDelivery('error');
    } else {
      setValidateTypeDelivery(null);
    }
  }

  function handlePutTypeDelivery(event: any) {
    actionCustomerReport.changeTypeDeliveryCustomerReport(event);

    if (event === null || event.length === 0) {
      setValidateTypeDelivery('error');
    } else {
      setValidateTypeDelivery(null);
    }
  }

  function handleConvert(e: any) {
    setfileName(e.target.value);
    let fileData = file;
    fileData = e.target.files[0];
    handleGetBase64(fileData)
      .then((result: any) => {
        actionCustomerReport.changeFileDataCustomerReport(result.split(',')[1]);
        file['base64'] = result.toString().split(',')[1];
        setfile(fileData);
      })
      .catch(err => {
        console.log(err);
      });

    setfile(e.target.files[0]);
  }

  const handleGetBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  function handlePutColumn(event: any) {
    const reg = /^-?\d*(\\d*)?$/;

    if (reg.test(event.target.value)) {
      actionCustomerReport.changeIndexColumnCustomerReport(event.target.value);

      if (event.target.value === '') {
        setValidateColumn('error');
      } else {
        setValidateColumn('');
      }
    }
  }

  function handleCheckboxBarcode() {
    actionCustomerReport.changeCheckBoxBarcodeCustomerReport();
  }

  function handlePutCheckBoxFileName() {
    actionCustomerReport.changeCheckBoxFileNameCustomerReport();
  }

  function handlePutCheckBoxFullData() {
    actionCustomerReport.changeCheckBoxFullDataCustomerReport();
  }

  function handlePutCheckBoxToDayData() {
    actionCustomerReport.changeCheckBoxToDayCustomerReport();
  }

  function handleReset() {
    actionCustomerReport.clearCustomerReportForm();
    actionListCustomer.clearListCustomer();
    actionListCustomerProduct.clearListCustomerProduct();
    actionResetListReportFormat.clearListCustomerReport();
    setValuePickupDate(null);
    setValidatePickupDate(null);
    setValidateConditionPickupDate(null);
    setValidateCustomer(null);
    setValidateCustomerProduct(null);
    setValidateReportFormat(null);
    setValidateDeliveryStatus(null);
    setValidateColumn(null);
    setcollapse(null);
    setfile(null);
    setfileName('');
  }

  function handleDownloadCustomerReport() {
    actionResetListReportFormat.changeDetailEditCustomerConfigReport();
  }

  function handleCollapse() {
    actionCustomerReport.changeFileDataCustomerReport(null);
    actionCustomerReport.changeIndexColumnCustomerReport(null);
    if (CheckBoxBarcode === true) {
      actionCustomerReport.changeCheckBoxBarcodeCustomerReport();
    }
    setcollapse(!collapse);
    setfile(null);
    setfileName('');
    setValidateColumn(null);
  }

  return (
    <div>
      <CustomerReportComponent
        isLoading={isLoading}
        radioButton={RadioButton}
        datePickerMonth={DatePickerMonth}
        valuePickupDate={ValuePickupDate}
        pickupDate={PickupDate}
        customer={Customer}
        customerProduct={CustomerProduct}
        reportFormat={ReportFormat}
        deliveryStatus={DeliveryStatus}
        treatmentDocument={TreatmentDocument}
        statusSuccess={StatusSuccess}
        statusReturn={StatusReturn}
        typeDelivery={TypeDelivery}
        fileName={fileName}
        fileData={FileData}
        indexColumn={IndexColumn}
        checkboxBarcode={CheckBoxBarcode}
        checkboxFileName={CheckBoxFileName}
        checkboxFullData={CheckBoxFullData}
        CheckBoxToDayData={CheckBoxToDayData}
        collapse={collapse}
        loadingSelectCustomer={LoadingSelectCustomer}
        loadingSelectCustomerProduct={LoadingSelectCustomerProduct}
        loadingSelectReportFormat={LoadingSelectReportFormat}
        validatePickupDate={ValidatePickupDate === 'error' ? 'error' : ''}
        validateCustomer={ValidateCustomer === 'error' ? 'error' : ''}
        validateCustomerProduct={
          ValidateCustomerProduct === 'error' ? 'error' : ''
        }
        validateReportFormat={ValidateReportFormat === 'error' ? 'error' : ''}
        validateDeliveryStatus={
          ValidateDeliveryStatus === 'error' ? 'error' : ''
        }
        validateTreatmentDocument={
          ValidateTreatmentDocument === 'error' ? 'error' : ''
        }
        validateStatusSuccess={ValidateStatusSuccess === 'error' ? 'error' : ''}
        validateStatusReturn={ValidateStatusReturn === 'error' ? 'error' : ''}
        validateTypeDelivery={ValidateTypeDelivery === 'error' ? 'error' : ''}
        validateColumn={ValidateColumn === 'error' ? 'error' : ''}
        helpValidatePickupDate={
          ValidatePickupDate === 'error'
            ? RadioButton === 'Cycle'
              ? 'Tanggal Cycle Wajib Diisi'
              : RadioButton === 'Process'
              ? 'Tanggal Proses Wajib Diisi'
              : RadioButton === 'Finish'
              ? 'Tanggal Status Wajib Diisi'
              : ''
            : ''
        }
        helpValidateCustomer={
          ValidateCustomer === 'error' ? 'Pelanggan wajib diisi' : ''
        }
        helpValidateCustomerProduct={
          ValidateCustomerProduct === 'error'
            ? 'Produk pelanggan wajib diisi'
            : ''
        }
        helpValidateReportFormat={
          ValidateReportFormat === 'error' ? 'Format laporan wajib diisi' : ''
        }
        helpValidateDeliveryStatus={
          ValidateDeliveryStatus === 'error'
            ? 'Status pengiriman wajib diisi'
            : ''
        }
        helpValidateTreatmentDocument={
          ValidateTreatmentDocument === 'error'
            ? 'Perlakuan Dokumen wajib diisi'
            : ''
        }
        helpValidateStatusSuccess={
          ValidateStatusSuccess === 'error' ? 'Status diterima wajib diisi' : ''
        }
        helpValidateStatusReturn={
          ValidateStatusReturn === 'error'
            ? 'Status dikembalikan wajib diisi'
            : ''
        }
        helpValidateTypeDelivery={
          ValidateTypeDelivery === 'error' ? 'Tipe pengiriman wajib diisi' : ''
        }
        helpValidateColumn={
          ValidateColumn === 'error'
            ? CheckBoxBarcode === false
              ? 'Index waybill wajib diisi'
              : 'Index barcode wajib diisi'
            : ''
        }
        listCustomer={ListCustomer}
        listCustomerProduct={ListCustomerProduct}
        listReportFormat={ListReportFormat}
        listStatusSuccess={ListStatusSuccess}
        listStatusReturn={ListStatusReturn}
        handleRadioButton={handleRadioButton}
        handleDatePickerMonth={handleDatePickerMonth}
        handleValidatePickupDate={handleValidatePickupDate}
        handlePutPickupDate={handlePutPickupDate}
        handlePutCustomer={handlePutCustomer}
        handlePutCustomerProduct={handlePutCustomerProduct}
        handlePutReportFormat={handlePutReportFormat}
        handlePutDeliveryStatus={handlePutDeliveryStatus}
        handlePutTreatmentDocument={handlePutTreatmentDocument}
        handlePutStatusSuccess={handlePutStatusSuccess}
        handlePutStatusReturn={handlePutStatusReturn}
        handlePutTypeDelivery={handlePutTypeDelivery}
        handlePutColumn={handlePutColumn}
        handlePutCheckBoxFileName={handlePutCheckBoxFileName}
        handlePutCheckBoxFullData={handlePutCheckBoxFullData}
        handlePutCheckBoxToDayData={handlePutCheckBoxToDayData}
        handleBlurCustomer={handleBlurCustomer}
        handleBlurCustomerProduct={handleBlurCustomerProduct}
        handleBlurReportFormat={handleBlurReportFormat}
        handleBlurDeliveryStatus={handleBlurDeliveryStatus}
        handleBlurTreatmentDocument={handleBlurTreatmentDocument}
        handleBlurStatusSuccess={handleBlurStatusSuccess}
        handleBlurStatusReturn={handleBlurStatusReturn}
        handleBlurTypeDelivery={handleBlurTypeDelivery}
        handleGetCustomer={handleGetCustomer}
        handleGetCustomerProduct={handleGetCustomerProduct}
        handleGetReportFormat={handleGetReportFormat}
        handleConvert={handleConvert}
        handleCheckboxBarcode={handleCheckboxBarcode}
        handleCollapse={handleCollapse}
        handleResetCustomer={handleResetCustomer}
        handleReset={handleReset}
        handleDownloadCustomerReport={handleDownloadCustomerReport}
        handleDownloadReport={handleDownloadReport}
      />
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  isLoading: state.Toggle.isLoading,
  RadioButton: state.Report.CustomerReportForm.RadioButton,
  DatePickerMonth: state.Report.CustomerReportForm.DatePickerMonth,
  PickupDate: state.Report.CustomerReportForm.PickupDate,
  Customer: state.Report.CustomerReportForm.CustomerId,
  CustomerProduct: state.Report.CustomerReportForm.CustomerProductId,
  ReportFormat: state.Report.CustomerReportForm.ReportFormat,
  DeliveryStatus: state.Report.CustomerReportForm.DeliveryStatus,
  TreatmentDocument: state.Report.CustomerReportForm.TreatmentDocument,
  StatusSuccess: state.Report.CustomerReportForm.StatusSuccess,
  StatusReturn: state.Report.CustomerReportForm.StatusReturn,
  TypeDelivery: state.Report.CustomerReportForm.TypeDelivery,
  FileData: state.Report.CustomerReportForm.FileData,
  IndexColumn: state.Report.CustomerReportForm.IndexColumn,
  CheckBoxBarcode: state.Report.CustomerReportForm.CheckBoxBarcode,
  CheckBoxFileName: state.Report.CustomerReportForm.CheckBoxFileName,
  CheckBoxFullData: state.Report.CustomerReportForm.CheckBoxFullData,
  CheckBoxToDayData: state.Report.CustomerReportForm.CheckBoxToDayData,
  ListCustomer: state.Customer.ListCustomer,
  ListCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  ListReportFormat: state.Customer.ListCustomerReport,
  ListStatusSuccess: state.Report.CustomerReportForm.ListStatusSuccess,
  ListStatusReturn: state.Report.CustomerReportForm.ListStatusReturn,
  LoadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  LoadingSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
  LoadingSelectReportFormat:
    state.Customer.PropertiesCustomer.LoadingCustomerReportFormat,
  FormatReport: state.Customer.FormCustomerConfigReport.FormatReport,
  CustomerName: state.Report.CustomerReportForm.CustomerName,
  CustomerId: state.Report.CustomerReportForm.CustomerId,
  CustomerProductId: state.Report.CustomerReportForm.CustomerProductId,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionCustomerReport: bindActionCreators(
    ActionDownloadCustomerReport,
    dispatch
  ),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
  actionListReportFormat: bindActionCreators(ActionListReportFormat, dispatch),
  actionResetListReportFormat: bindActionCreators(
    ActionResetListReportFormat,
    dispatch
  ),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CustomerReportContainer);
