import React from 'react';
import { Modal, Spin } from 'antd';

type ModalDownloadProgressProps = {
  loading: boolean;
  loadingProgress: number;
  title?: string;
  description?: string;
};

export default function ModalDownloadProgress({
  loading,
  loadingProgress,
  title = 'Download Data',
  description = 'Sedang mendownload data, mohon tunggu...',
}: ModalDownloadProgressProps) {
  return (
    <Modal
      visible={loading || loadingProgress !== 0}
      footer={null}
      closable={false}
      bodyStyle={{ padding: 24 }}
    >
      <div style={{ display: 'flex', gap: 16 }}>
        <Spin />
        <p
          style={{
            margin: 0,
            fontSize: 18,
            color: '#7956EE',
            fontWeight: 600,
            lineHeight: '20px',
          }}
        >
          {title}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          marginLeft: '37px',
        }}
      >
        <p
          style={{
            color: '#9E9E9E',
            fontSize: 14,
            fontWeight: 500,
            margin: 0,
          }}
        >
          {description}
        </p>
        <p
          style={{
            color: '#9E9E9E',
            fontSize: 14,
            fontWeight: 500,
            margin: 0,
          }}
        >
          {loadingProgress} %
        </p>
      </div>
    </Modal>
  );
}
